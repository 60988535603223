<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("pos") + " " + $t("admin") }}</v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="80%">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="$t('brands.name')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <input
                      v-model="selected.address"
                      type="text"
                      :placeholder="$t('address')"
                      ref="googleaddressbar"
                      class="googleAddressFinder"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.town"
                      v-bind:label="$t('town')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.postalCode"
                      label="CP"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.code"
                      label="Code"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.geographyId"
                      v-bind:label="$t('geography')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="label"
                      item-value="id"
                      :search-input.sync="search_geography"
                      :loading="isGeographySearching"
                      :items="filtered_geography"
                      hide-no-data
                      hide-selected
                      filled
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.zoneId"
                      v-bind:label="$t('zone')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_zone"
                      :loading="isZoneSearching"
                      :items="filtered_zone"
                      hide-no-data
                      hide-selected
                      filled
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.storeM2"
                      type="number"
                      v-bind:label="$t('Store m2')"
                      :rules="storem2Rules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.latitude"
                      v-bind:label="$t('latitude')"
                      :type="'number'"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.longitude"
                      v-bind:label="$t('longitude')"
                      :type="'number'"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <contactPersonTable
                      :posData="selected"
                      :updatePosContacts="updatePosContacts"
                      :apiGetBrandsUrl="
                        `worksession/getbasedata?isFlag=searchbrand`
                      "
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.comments"
                      v-bind:label="$t('comments')"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.chainId"
                      v-bind:label="$t('chain')"
                      :items="chain_list"
                      item-text="name"
                      item-value="id"
                      filled
                      required
                      :rules="requiredRules"
                      @change="onChangeChainId(selected.chainId, 1)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.channelId"
                      v-bind:label="$t('channel')"
                      :items="channel_list"
                      item-text="name"
                      item-value="id"
                      filled
                      required
                      :rules="requiredRules"
                      @change="onChangeChannelId(selected.channelId, 1)"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.subChainId"
                      v-bind:label="`Sub ` + $t('chain')"
                      :items="sub_chain_list"
                      item-text="name"
                      item-value="id"
                      filled
                      clearable
                      @change="onChangeChainId(selected.subChainId, 2)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.subChannelId"
                      v-bind:label="`Sub ` + $t('channel')"
                      :items="sub_channel_list"
                      item-text="name"
                      item-value="id"
                      filled
                      clearable
                      @change="onChangeChannelId(selected.subChannelId, 2)"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.sub2ChainId"
                      v-bind:label="`Sub-Sub ${$t('chain')}`"
                      :items="sub2_chain_list"
                      item-text="name"
                      item-value="id"
                      filled
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.sub2ChannelId"
                      v-bind:label="`Sub-Sub ${$t('channel')}`"
                      :items="sub2_channel_list"
                      item-text="name"
                      item-value="id"
                      filled
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="d-flex justify-center"
                  >
                    <v-radio-group
                      v-model="selected.status"
                      row
                      required
                      :rules="requiredRules"
                    >
                      <v-radio label="Active" value="active"></v-radio>
                      <v-radio label="Inactive" value="inactive"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveClick(selected)">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="600px">
        <v-card>
          <v-card-title class="text-h6">{{
            $t("confirm_delete")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="downloadExcelTemplateAPI('POS_ADMIN')"
              class="mb-2 ml-2"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="mb-2 ml-2"
              @click="onUploadExcelClick('add')"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Import Excel
            </v-btn>
            <v-btn
              color="info"
              dark
              class="mb-2 ml-2"
              @click="onUploadExcelClick('update')"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Actualizar POS
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn
              color="warning"
              dark
              class="mb-2 ml-2"
              @click="downloadExcel"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefposes"
              id="poses_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="poses"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totalposes"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";
import contactPersonTable from "@/components/contactPersonTable";

export default {
  name: "posadmin",
  components: {
    contactPersonTable
  },
  data: function() {
    return {
      addressAutoComplete: null,
      filterable_col_list: [],
      filterCriteria: {
        id: {},
        status: {},
        name: {},
        address: {},
        postalCode: {},
        town: {},
        phone: {},
        phone2: {},
        contact: {},
        comments: {},
        chainId: {},
        subChainId: {},
        sub2ChainId: {},
        channelId: {},
        subChannelId: {},
        sub2ChannelId: {},
        zoneId: {},
        country_name: {},
        state_name: {},
        province_name: {},
        storeM2: {},
        activeBrands: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      showEmpty: false,

      totalposes: 0,
      poses: [],

      valid: true,
      requiredRules: [v => !!v || "Required"],
      storem2Rules: [
        v =>
          !v ||
          (v >= 0 && v <= 2000) ||
          "Incorrect value ! this value should be between 0 and 2000."
      ],
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      showEdit: false,
      dialogDelete: false,

      search_geography: null,
      isGeographySearching: false,
      filtered_geography: [],

      search_zone: null,
      isZoneSearching: false,
      filtered_zone: [],

      chain_list: [],
      sub_chain_list: [],
      sub2_chain_list: [],
      channel_list: [],
      sub_channel_list: [],
      sub2_channel_list: [],
      // visitTypes: [],

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",
      funcType: null,

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      isLoadingSpinner: false
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async search_geography(val) {
      this.search_geography_list(val);
    },
    async search_zone(val) {
      this.search_zone_list(val);
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      return [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: 70,
          sortBy: "ASC",
          fixed: "left",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["id"]["from"]}
                    value={this.filterCriteria["id"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["id"]["to"]}
                    value={this.filterCriteria["id"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "id")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "name",
          title: this.$t("salesforce.name"),
          field: "name",
          align: "left",
          sortBy: "",
          fixed: "left",
          width: 200,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["name"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "address",
          title: this.$t("address"),
          field: "address",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["address"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`address`}
                    isFullText={false}
                    filterCriteria={this.filterCriteria["address"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "address")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "postalCode",
          title: "CP",
          field: "postalCode",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["postalCode"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`postalCode`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["postalCode"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "postalCode")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "town",
          title: this.$t("town"),
          field: "town",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["town"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`town`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["town"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "town")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        // {
        //   key: "visitType",
        //   title: this.$t("type"),
        //   field: "visitType",
        //   align: "left",
        //   sortBy: "",
        //   width: 150,
        // },
        {
          key: "country_name",
          title: this.$t("country"),
          field: "country_name",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["country_name"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`country_name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["country_name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "country_name")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "state_name",
          title: this.$t("state"),
          field: "state_name",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["state_name"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`state_name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["state_name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "state_name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "province_name",
          title: this.$t("province"),
          field: "province_name",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["province_name"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`province_name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["province_name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "province_name")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "zoneId",
          title: this.$t("zone"),
          field: "zoneId",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.zone !== null ? row.zone.name : ""}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["zoneId"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`zoneId`}
                    isFullText={false}
                    filterCriteria={this.filterCriteria["zoneId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "zoneId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "latitude",
          title: this.$t("latitude"),
          field: "latitude",
          align: "left",
          sortBy: "",
          width: 150
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <input
          //           type="number"
          //           class="filter_date_picker"
          //           v-model={this.filterCriteria["latitude"]["from"]}
          //           value={this.filterCriteria["latitude"]["from"]}
          //           placeholder="From - "
          //         />
          //         <br />
          //         <input
          //           type="number"
          //           class="filter_date_picker"
          //           v-model={this.filterCriteria["latitude"]["to"]}
          //           value={this.filterCriteria["latitude"]["to"]}
          //           placeholder="To - "
          //         />
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchCancel(closeFn, "latitude")}
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   },
          // },
        },
        {
          key: "longitude",
          title: this.$t("longitude"),
          field: "longitude",
          align: "left",
          sortBy: "",
          width: 150
        },
        {
          key: "storeM2",
          title: this.$t("Store m2"),
          field: "storeM2",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["storeM2"]["from"]}
                    value={this.filterCriteria["storeM2"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["storeM2"]["to"]}
                    value={this.filterCriteria["storeM2"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "storeM2")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "comments",
          title: this.$t("comments"),
          field: "comments",
          align: "left",
          sortBy: "",
          width: 200,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["comments"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`comments`}
                    isFullText={false}
                    filterCriteria={this.filterCriteria["comments"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "comments")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "chainId",
          title: this.$t("chain"),
          field: "chainId",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.chain && row.chain.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["chainId"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`chainId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["chainId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "chainId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "subChainId",
          title: "Sub " + this.$t("chain"),
          field: "subChainId",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.subchain && row.subchain.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["subChainId"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`subChainId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["subChainId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "subChainId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "sub2ChainId",
          title: "Sub-Sub " + this.$t("chain"),
          field: "sub2ChainId",
          align: "left",
          sortBy: "",
          width: 170,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.sub2chain && row.sub2chain.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["sub2ChainId"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`sub2ChainId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["sub2ChainId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "sub2ChainId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "channelId",
          title: this.$t("channel"),
          field: "channelId",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.channel && row.channel.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["channelId"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`channelId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["channelId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "channelId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "subChannelId",
          title: "Sub " + this.$t("channel"),
          field: "subChannelId",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.subchannel && row.subchannel.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["subChannelId"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`subChannelId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["subChannelId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "subChannelId")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "sub2ChannelId",
          title: "Sub-Sub " + this.$t("channel"),
          field: "sub2ChannelId",
          align: "left",
          sortBy: "",
          width: 170,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.sub2channel && row.sub2channel.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["sub2ChannelId"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`sub2ChannelId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["sub2ChannelId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "sub2ChannelId")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "activeBrands",
          title: "Activos " + this.$t("brands.brands"),
          field: "activeBrands",
          align: "left",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.activeBrandNames}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["activeBrands"]}
                    apiUrl={`pos/getfilterlist`}
                    columnName={`activeBrands`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["activeBrands"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "activeBrands")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: this.$t("status"),
          field: "status",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["active"]}
                    label="Active"
                    value="active"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["inactive"]}
                    label="InActive"
                    value="inactive"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "actions",
          title: this.$t("edititem"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
              </div>
            );
          }
        }
      ];
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    }
  },
  methods: {
    async search_geography_list(val) {
      let filterable = this.filtered_geography.filter(
        item => item.label === val
      );
      let search_str = val;
      if (!val) search_str = "";
      if (filterable.length === 0) {
        this.isGeographySearching = true;
        let fetch_url = "pos/get_geographies?filter_name=" + search_str;
        this.prevsearch = search_str;
        const resp = await ApiService.get(fetch_url);
        this.filtered_geography = resp.data;
        this.isGeographySearching = false;
      }
    },
    async search_zone_list(val) {
      let search_str = val;
      if (!val) search_str = "";
      let fetch_url = "pos/get_zones?filter_name=" + search_str;
      this.isZoneSearching = true;
      const resp = await ApiService.get(fetch_url);
      this.filtered_zone = resp.data;
      this.isZoneSearching = false;
    },
    placeChangedListener() {
      var address = this.addressAutoComplete.getPlace();
      console.log(address);
      console.log(
        address.geometry.location.lat(),
        address.geometry.location.lng()
      );
      if (address && address.address_components) {
        let addressDetail = {
          address: address.formatted_address,
          town: null,
          postalCode: null,
          latitude: null,
          longitude: null
        };
        // types - postal_code
        let postal_code_item = address.address_components.filter(el =>
          el.types.includes("postal_code")
        );
        if (postal_code_item && postal_code_item.length > 0) {
          addressDetail.postalCode = postal_code_item[0].long_name;
        }
        // town types - locality
        let town_item = address.address_components.filter(el =>
          el.types.includes("locality")
        );
        if (town_item && town_item.length > 0) {
          addressDetail.town = town_item[0].long_name;
        }
        console.log("addressDetail - ", addressDetail);

        if (address.geometry) {
          addressDetail.latitude = address.geometry.location.lat();
          addressDetail.longitude = address.geometry.location.lng();
        }

        this.selected = { ...this.selected, ...addressDetail };
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (["latitude", "longitude"].includes(cancelFilterKey)) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else {
        this.filterCriteria[cancelFilterKey] = {};
      }
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#poses_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    async getChainList(parentId, subDepth) {
      let fetch_url = `pos/get_chain_channel_list?isFlag=chainlist`;
      console.log(parentId, subDepth);
      let chainList = [];
      if (parentId) {
        fetch_url += "&parentId=" + parentId;
        let { chain_list } = await ApiService.get(fetch_url);
        chainList = chain_list;
      }
      return chainList;
    },
    async getChannelList(parentId, subDepth) {
      let fetch_url = `pos/get_chain_channel_list?isFlag=channel_list`;
      console.log(parentId, subDepth);
      let channelList = [];
      if (parentId) {
        fetch_url += "&parentId=" + parentId;
        let { channel_list } = await ApiService.get(fetch_url);
        channelList = channel_list;
      }
      return channelList;
    },
    async onChangeChainId(parentId, subDepth) {
      let chainList = await this.getChainList(parentId, subDepth);
      if (subDepth === 1) {
        this.sub_chain_list = chainList;
        this.sub2_chain_list = [];
        this.selected.subChainId = null;
        this.selected.sub2ChainId = null;
      } else if (subDepth === 2) {
        this.sub2_chain_list = chainList;
        this.selected.sub2ChainId = null;
      }
    },
    async onChangeChannelId(parentId, subDepth) {
      let channelList = await this.getChannelList(parentId, subDepth);
      if (subDepth === 1) {
        this.sub_channel_list = channelList;
        this.sub2_channel_list = [];
        this.selected.subChannelId = null;
        this.selected.sub2ChannelId = null;
      } else if (subDepth === 2) {
        this.sub2_channel_list = channelList;
        this.selected.sub2ChannelId = null;
      }
    },
    async createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        address: _.get(item, "address"),
        town: _.get(item, "town"),
        postalCode: _.get(item, "postalCode"),
        code: _.get(item, "code"),
        geographyId: _.get(item, "geographyId"),
        geography: _.get(item, "geography"),
        zone: _.get(item, "zone"),
        // visitType: _.get(item, "visitType"),
        latitude: _.get(item, "latitude"),
        longitude: _.get(item, "longitude"),
        phone: _.get(item, "phone"),
        phone2: _.get(item, "phone2"),
        contact: _.get(item, "contact"),
        comments: _.get(item, "comments"),
        chainId: _.get(item, "chainId"),
        subChainId: _.get(item, "subChainId"),
        sub2ChainId: _.get(item, "sub2ChainId"),
        channelId: _.get(item, "channelId"),
        subChannelId: _.get(item, "subChannelId"),
        sub2ChannelId: _.get(item, "sub2ChannelId"),
        status: _.get(item, "status"),
        zoneId: _.get(item, "zoneId"),
        storeM2: _.get(item, "storeM2"),
        pos_contacts: _.get(item, "pos_contacts")
      };
      if (!model.id) {
        model.status = "active";
      }
      await this.search_geography_list("");
      await this.search_zone_list("");
      if (model) {
        if (model?.subChainId || model?.chainId) {
          this.sub_chain_list = await this.getChainList(model.chainId, 1);
          this.sub2_chain_list = [];
        }
        if (model?.sub2ChainId || model?.subChainId) {
          this.sub2_chain_list = await this.getChainList(model.subChainId, 2);
        }
        if (model?.subChannelId || model?.channelId) {
          this.sub_channel_list = await this.getChannelList(model.channelId, 1);
          this.sub2_channel_list = [];
        }
        if (model?.sub2ChannelId || model.subChannelId) {
          this.sub2_channel_list = await this.getChannelList(
            model.subChannelId,
            2
          );
        }
      }
      console.log(model);
      return model;
    },
    async onItemEdit(item) {
      this.selected = await this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
      this.$nextTick(function() {
        this.addressAutoComplete = new window.google.maps.places.Autocomplete(
          this.$refs.googleaddressbar
        );
        this.addressAutoComplete.addListener(
          "place_changed",
          this.placeChangedListener
        );
      });
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async onSaveClick(item) {
      console.log(item);
      if (this.$refs.form.validate()) {
        let body = item;
        let status = null;
        if (body.id) {
          status = await ApiService.put(`pos/${body.id}`, body);
        } else {
          status = await ApiService.post(`pos/`, body);
        }
        if (status?.success) {
          logInfo("POS actualizada");
        } else if (status?.success === false && status?.message) {
          logError(status.message);
        }
        this.showEdit = false;
        this.getDataFromApi();
      }
    },
    async updatePosContacts(newPosContacts) {
      this.selected.pos_contacts = newPosContacts;
      await this.getDataFromApi();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },
    onItemRemove(item_id) {
      console.log(item_id);
      this.deleteItemId = item_id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      if (this.deleteItemId > 0) {
        this.closeDelete();
        ApiService.delete(`pos/${this.deleteItemId}`).then(() => {
          this.getDataFromApi();
        });
      }
    },
    async getDataFromApi() {
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector: "#poses_table .filterable-column"
      });

      let fetch_url = "pos";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.poses = resp.data;
        // this.visitTypes = resp.visitTypes;
        this.chain_list = resp.chain_list;
        this.channel_list = resp.channel_list;

        if (this.poses.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totalposes = resp.total;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector: "#poses_table .filterable-column"
      });
      console.log(query_params_string);
      let fetch_url = "pos/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "pos.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onUploadExcelClick(funcType) {
      this.funcType = funcType;
      window.addEventListener("focus", () => {}, { once: false });
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile, this.funcType);
      if (
        window.confirm(
          `¿Está seguro de que desea cargar este archivo de Excel?`
        )
      ) {
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        this.isLoadingSpinner = true;
        try {
          let response = null;
          if (this.funcType === "add") {
            response = await ApiService.post(`pos/uploadexcel_add`, data, {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            });
          } else if (this.funcType === "update") {
            response = await ApiService.post(`pos/uploadexcel_update`, data, {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            });
          }
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
            this.getDataFromApi();
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
            this.getDataFromApi();
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
        } catch (error) {
          console.log(error);
        }
        this.isLoadingSpinner = false;
      }
    },
    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.filterable_col_list = Object.keys(this.filterCriteria);
    this.init();
  }
};
</script>

<style>
input.googleAddressFinder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition-property: height, min-height;
  margin-top: 16px;
  font-size: 16px;
  height: 33px;
}
</style>
